import React from "react"
import * as ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import { SENTRY_DSN } from "./config"
import { Themes } from "./containers"
import { LicenseInfo } from "@mui/x-data-grid-pro"
import "./i18n"

if (SENTRY_DSN) {
	Sentry.init({ dsn: SENTRY_DSN })
}

LicenseInfo.setLicenseKey("33ff0ec4e6998b4810a344a24f88b373T1JERVI6MTgzNDYsRVhQSVJZPTE2MzU0Njk0NTgwMDAsS0VZVkVSU0lPTj0x")

function importBuildTarget() {
	// DefinePlugin in webpack.config.js will substitute
	// process.env.REACT_APP_BUILD_TARGET with it's value at build time.
	// https://webpack.js.org/plugins/define-plugin/

	// TerserPlugin in webpack.config.js will eliminate dead code
	// ...if we make it easy enough (no maps or switches, etc).
	// https://webpack.js.org/plugins/terser-webpack-plugin/

	if (process.env.REACT_APP_BUILD_TARGET === "ADMIN") {
		document.title = "GrowNet - Admin"
		return import("./AppAdmin/Admin")
	} else if (process.env.REACT_APP_BUILD_TARGET === "PUBLIC") {
		document.title = "GrowNet"
		return import("./AppPublic/Public")
	} else {
		return Promise.reject(new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET))
	}
}

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) => {
	return ReactDOM.render(
		<React.StrictMode>
			<Themes.Provider>
				<Environment />
			</Themes.Provider>
		</React.StrictMode>,
		document.getElementById("root"),
	)
})
