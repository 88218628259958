import { grey } from "@mui/material/colors"
import { createTheme, ThemeOptions } from "@mui/material/styles"
import AvatarDefault from "../assets/images/brandIcon.svg"

declare module "@mui/material/styles" {
	interface PaletteOptions {
		lightgrey: string,
		grey500?: string,
	}
}

const baseTheme: ThemeOptions = {
	palette: {
		primary: {
			main: "#69C917",
		},
		secondary: {
			main: "#f50057",
		},
		success: {
			main: "#44b700",
		},
		background: {
			default: "#F7F9FC",
		},
		grey500: grey[500] || "#9e9e9e",
		lightgrey: "#f7f7f7",
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1440,
		},
	},
	typography: {
		fontFamily: [
			"Nunito",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 600,
		h1: {
			fontSize: "2rem",
			fontWeight: 600,
			lineHeight: 1.2,
		},
		h2: {
			fontSize: "1.75rem",
			fontWeight: 600,
			lineHeight: 1.2,
		},
		h3: {
			fontSize: "1.5rem",
			fontWeight: 600,
			lineHeight: 1.2,
		},
		h4: {
			fontSize: "1.25rem",
			fontWeight: 600,
			lineHeight: 1.2,
		},
		h5: {
			fontSize: "1.125rem",
			fontWeight: 600,
			lineHeight: 1.2,
		},
		h6: {
			fontSize: "1.0625rem",
			fontWeight: 600,
			lineHeight: 1.2,
		},
		button: {
			textTransform: "none",
		},
	},
	components: {
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: { variant: "h6" },
			},
		},
		MuiAvatar: {
			defaultProps: {
				src: AvatarDefault,
			},
		},
		MuiButton: {
			defaultProps: {
				color: "inherit",
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontWeight: 600,
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: "#bfbfbf9c",
					color: "#0F0F0F",
					fontSize: "0.75rem",
					backdropFilter: "blur(4px)",
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				avatarColorPrimary: {
					color: "inherit",
					backgroundColor: "inherit",
				},
			},
		},
	},
}

const adminTheme = createTheme({
	...baseTheme,
})

const publicTheme = createTheme({
	...baseTheme,
	palette: {
		...baseTheme.palette,
		lightgrey: "#f7f7f7",
		background: {
			default: "#F5F5F5",
		},
	},
	typography: {
		...baseTheme.typography,
		fontFamily: [
			"Poppins",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
	},
})

const themes = [adminTheme, publicTheme]

export default themes
