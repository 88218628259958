export enum Perm {
	RoleList = "RoleList",
	RoleCreate = "RoleCreate",
	RoleRead = "RoleRead",
	RoleUpdate = "RoleUpdate",
	RoleArchive = "RoleArchive",
	RoleUnarchive = "RoleUnarchive",

	UserList = "UserList",
	UserCreate = "UserCreate",
	UserRead = "UserRead",
	UserUpdate = "UserUpdate",
	UserArchive = "UserArchive",
	UserUnarchive = "UserUnarchive",
	UserForceDisconnect = "UserForceDisconnect",

	OrganisationList = "OrganisationList",
	OrganisationCreate = "OrganisationCreate",
	OrganisationRead = "OrganisationRead",
	OrganisationUpdate = "OrganisationUpdate",
	OrganisationArchive = "OrganisationArchive",
	OrganisationUnarchive = "OrganisationUnarchive",

	LotList = "LotList",
	LotRead = "LotRead",
	LotArchive = "LotArchive",
	LotUnarchive = "LotUnarchive",

	OrderList = "OrderList",

	AdminPortal = "AdminPortal",
	ImpersonateUser = "ImpersonateUser",
	UserActivityList = "UserActivityList",
}

export enum PermGroup {
	Role = "Role",
	Organisation = "Organisation",
	User = "User",
	Other = "Other",
}

export enum ObjectType {
	Self = "Self",
	User = "User",
	Organisation = "Organisation",
	Role = "Role",
	Blob = "Blob",
}

export enum FilterBy {
	All = "All",
	Active = "Active",
	Archived = "Archived",
}

export enum SortDir {
	Ascending = "Ascending",
	Descending = "Descending",
}
