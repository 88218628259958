enum HubKey {
	Welcome = "WELCOME",

	AuthLogin = "AUTH:LOGIN",
	AuthLoginToken = "AUTH:TOKEN",
	AuthLoginGoogle = "AUTH:GOOGLE",
	AuthLoginFacebook = "AUTH:FACEBOOK",
	AuthLogout = "AUTH:LOGOUT",
	AuthSuccess = "AUTH:SUCCESS",
	AuthSendVerifyEmail = "AUTH:SEND_VERIFY_EMAIL",
	AuthVerifyAccount = "AUTH:VERIFY_ACCOUNT",

	UserGet = "USER:GET",
	UserList = "USER:LIST",
	UserCreate = "USER:CREATE",
	UserUpdate = "USER:UPDATE",
	/** Tracks user changes */
	UserUpdated = "USER:UPDATED",
	UserArchive = "USER:ARCHIVE",
	UserUnarchive = "USER:UNARCHIVE",
	UserChangePassword = "USER:CHANGE_PASSWORD",
	UserOnlineStatusChange = "USER:ONLINE_STATUS_CHANGE",
	UserForceDisconnect = "USER:FORCE_DISCONNECT",
	UserForceDisconnected = "USER:FORCE_DISCONNECTED",
	UserImpersonate = "USER:IMPERSONATE",

	RoleGet = "ROLE:GET",
	RoleList = "ROLE:LIST",
	RoleCreate = "ROLE:CREATE",
	RoleUpdate = "ROLE:UPDATE",
	RoleArchive = "ROLE:ARCHIVE",
	RoleUnarchive = "ROLE:UNARCHIVE",

	OrganisationGet = "ORGANISATION:GET",
	OrganisationList = "ORGANISATION:LIST",
	OrganisationCreate = "ORGANISATION:CREATE",
	OrganisationUpdate = "ORGANISATION:UPDATE",
	OrganisationArchive = "ORGANISATION:ARCHIVE",
	OrganisationUnarchive = "ORGANISATION:UNARCHIVE",
	OrganisationBadges = "ORGANISATION:BADGES",
	OrganisationStats = "ORGANISATION:STATS",

	ProduceList = "PRODUCE:LIST",
	ProduceCategoryList = "PRODUCE:CATEGORY_LIST",
	ProduceTypeList = "PRODUCE:TYPE_LIST",

	PackStyleList = "PACK_STYLE:LIST",

	UnitList = "UNIT:LIST",

	ContainerList = "CONTAINER:LIST",
	ContainerSizeList = "CONTAINER:SIZE_LIST",

	LotCreate = "LOT:CREATE",
	LotDuplicate = "LOT:DUPLICATE",
	LotList = "LOT:LIST",
	LotGet = "LOT:GET",
	LotDispatchUpdate = "LOT:DISPATCH_UPDATE",
	LotSendUpdate = "LOT:SEND_UPDATE",
	LotPublish = "LOT:PUBLISH",
	LotMarketList = "LOT:MARKET_LIST",
	LotArchive = "LOT:ARCHIVE",
	LotUnarchive = "LOT:UNARCHIVE",
	LotNewSubscribe = "LOT:NEW_SUBSCRIBE",

	CertificationList = "CERTIFICATION:LIST",

	FavouriteUserRequest = "FAVOURITE:USER_REQUEST",
	FavouriteUserDelete = "FAVOURITE:USER_DELETE",
	FavouriteUserList = "FAVOURITE:USER_LIST",

	NegotiationList = "NEGOTIATION:LIST",
	NegotiationGet = "NEGOTIATION:GET",

	OfferCreate = "OFFER:CREATE",
	OfferList = "OFFER:LIST",
	OfferAccept = "OFFER:ACCEPT",
	OfferReject = "OFFER:REJECT",
	OfferCounter = "OFFER:COUNTER",
	OfferNewSubscribe = "OFFER:NEW_SUBSCRIBE",

	OrderList = "ORDER:LIST",
	OrderDispatch = "ORDER:DISPATCH",
	OrderReceived = "ORDER:RECEIVED",
	OrderSendPayment = "ORDER:SEND_PAYMENT",
	OrderReceivePayment = "ORDER:RECEIVE_PAYMENT",
	OrderLogList = "ORDER:LOG_LIST",
	OrderLogNewSubscribe = "ORDER:NEW_LOG_SUBSCRIBE",

	UserActivityList = "USER_ACTIVITY:LIST",
	UserActivityGet = "USER_ACTIVITY:GET",
	UserActivityCreate = "USER_ACTIVITY:CREATE",
}

export default HubKey
