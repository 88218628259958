import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import vi from "./locales/vi/translation.json"
const resources = {
	vi: {
		translation: vi,
	},
}

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		// debug: true,
		fallbackLng: "en",
		lng: "en",

		// setting key and namespace separators to false, so that keys can just be the fallback text in english
		keySeparator: false, // we do not use keys in form messages.welcome
		namespaceSeparator: false,

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	})

export default i18n
