import { useEffect, useState, useCallback } from "react"
import { createContainer } from "unstated-next"
import { User } from "../types/types"
import { useWebsocket } from "@/client"
import { PasswordLoginRequest, PasswordLoginResponse, TokenLoginRequest, TokenLoginResponse, VerifyAccountRequest, VerifyAccountResponse } from "../types/auth"
import HubKey from "../keys"
import { Perm } from "../types/enums"
import { GetUserRequest, ImpersonateUserResponse } from "../types/user"
import { PublicHostURL } from "../constants"

export enum VerificationType {
	EmailVerification,
	ForgotPassword,
}

/**
 * A Container that handles Authorisation
 */
export const AuthContainer = createContainer(() => {
	const admin = process.env.REACT_APP_BUILD_TARGET === "ADMIN"
	const [user, setUser] = useState<User | null>(null)
	const [authorised, setAuthorised] = useState(false)
	const [reconnecting, setReconnecting] = useState(false)
	const [loading, setLoading] = useState(true) // wait for loading current login state to complete first
	const [verifying, setVerifying] = useState(false)
	const [verifyCompleteType, setVerifyCompleteType] = useState<VerificationType>()
	const { state, send, subscribe, onReconnect } = useWebsocket()
	const [impersonatedUser, setImpersonatedUser] = useState<User>()

	/////////////////
	//  Functions  //
	/////////////////

	/**
	 * Logs user out by removing the stored login token and reloading the page.
	 */
	const logout = useCallback(() => {
		localStorage.removeItem("token")
		window.location.reload()
		setUser(null)
	}, [])

	/**
	 * Logs a User in using their email and password.
	 */
	const loginPassword = useCallback(
		async (email: string, password: string) => {
			try {
				if (state !== WebSocket.OPEN) {
					return
				}
				const resp = await send<PasswordLoginResponse, PasswordLoginRequest>(HubKey.AuthLogin, {
					email,
					password,
					admin,
				})
				if (!resp || !resp.user) {
					localStorage.removeItem("token")
					setUser(null)
					return
				}
				setUser(resp.user)
				localStorage.setItem("token", resp.token)
				setAuthorised(true)
			} catch (err) {
				console.error("login error", err)
			}
		},
		[send, state, admin],
	)

	/**
	 * Logs a User in using their saved login token.
	 *
	 * @param token login token usually from local storage
	 */
	const loginToken = useCallback(
		async (token: string) => {
			if (state !== WebSocket.OPEN) {
				return
			}
			setLoading(true)
			try {
				const resp = await send<TokenLoginResponse, TokenLoginRequest>(HubKey.AuthLoginToken, { token, admin })
				setUser(resp.user)
				setAuthorised(true)
			} catch {
				localStorage.removeItem("token")
				setUser(null)
			} finally {
				setLoading(false)
				setReconnecting(false)
			}
		},
		[send, state, admin],
	)

	/**
	 * Logs a User in using a Google oauth token
	 *
	 * @param token Google token id
	 */
	const loginGoogle = useCallback(
		async (token: string): Promise<string | null> => {
			if (state !== WebSocket.OPEN) {
				return null
			}
			try {
				const resp = await send<PasswordLoginResponse, TokenLoginRequest>(HubKey.AuthLoginGoogle, { token, admin })
				setUser(resp.user)
				if (!resp || !resp.user) {
					localStorage.removeItem("token")
					setUser(null)
					return null
				}
				setUser(resp.user)
				localStorage.setItem("token", resp.token)
				setAuthorised(true)
			} catch (e) {
				localStorage.removeItem("token")
				setUser(null)
				return typeof e === "string" ? e : "Something went wrong, please try again."
			}
			return null
		},
		[send, state, admin],
	)

	/**
	 * Logs a User in using a Facebook oauth token
	 *
	 * @param token Google token id
	 */
	const loginFacebook = useCallback(
		async (token: string): Promise<string | null> => {
			if (state !== WebSocket.OPEN) {
				return null
			}
			try {
				const resp = await send<PasswordLoginResponse, TokenLoginRequest>(HubKey.AuthLoginFacebook, { token, admin })
				setUser(resp.user)
				if (!resp || !resp.user) {
					localStorage.removeItem("token")
					setUser(null)
					return null
				}
				setUser(resp.user)
				localStorage.setItem("token", resp.token)
				setAuthorised(true)
			} catch (e) {
				localStorage.removeItem("token")
				setUser(null)
				return typeof e === "string" ? e : "Something went wrong, please try again."
			}
			return null
		},
		[send, state, admin],
	)

	/**
	 * Verifies a User and takes them to the next page.
	 */
	const verify = useCallback(
		async (token: string, forgotPassword?: boolean) => {
			if (state !== WebSocket.OPEN) {
				return
			}
			setVerifying(true)
			const resp = await send<VerifyAccountResponse, VerifyAccountRequest>(HubKey.AuthVerifyAccount, {
				token,
				forgotPassword,
			})
			if (!resp || !resp.user) {
				localStorage.removeItem("token")
				setUser(null)
				setVerifying(false)
				return resp
			}
			setUser(resp.user)
			setVerifying(false)
			setVerifyCompleteType(forgotPassword ? VerificationType.ForgotPassword : VerificationType.EmailVerification)
			localStorage.setItem("token", resp.token)
			setAuthorised(true)
			return resp
		},
		[send, state],
	)

	/** Checks if current user has a permission */
	const hasPermission = useCallback(
		(perm: Perm) => {
			if (impersonatedUser) return impersonatedUser.role.permissions.includes(perm)

			if (!user || !user.role || !user.role.permissions) return false
			return user.role.permissions.includes(perm)
		},
		[impersonatedUser, user],
	)

	/** Impersonate a User */
	const impersonateUser = useCallback(
		async (user?: User) => {
			if (user === undefined || impersonatedUser !== undefined) {
				setImpersonatedUser(undefined)
				if (!admin) {
					sessionStorage.removeItem("impersonate_token")
					setUser(null)
				}
			}
			if (!hasPermission(Perm.ImpersonateUser)) return

			if (!!user) {
				// Fetch user with full details
				const resp = await send<ImpersonateUserResponse, GetUserRequest>(HubKey.UserImpersonate, {
					id: user.id,
					username: user.username,
				})
				if (!resp) {
					return
				}
				if (!resp.token) {
					setImpersonatedUser(user)
					return
				}
				const newWindow = window.open(`${PublicHostURL}/login/${resp.token}`, "_blank", "noopener,noreferrer")
				if (newWindow) {
					newWindow.opener = null
				}
				return
			}
		},
		[hasPermission, send, admin, impersonatedUser],
	)

	/** Impersonate a User using impersonate token. */
	const impersonateUserAuth = useCallback(
		async (token: string) => {
			if (state !== WebSocket.OPEN) {
				return
			}
			try {
				const resp = await send<TokenLoginResponse, TokenLoginRequest>(HubKey.AuthLoginToken, { token, admin })
				if (!resp) {
					sessionStorage.removeItem("impersonate_token")
					return null
				}
				setUser(resp.user)
				setImpersonatedUser(resp.user)
				sessionStorage.setItem("impersonate_token", token)
				return resp.user
			} catch {
				sessionStorage.removeItem("impersonate_token")
			}
		},
		[send, state, admin],
	)

	///////////////////
	//  Use Effects  //
	///////////////////

	// Effect: Login with saved login token when websocket is ready
	useEffect(() => {
		if (user || state === WebSocket.CLOSED) return

		const impersonateToken = sessionStorage.getItem("impersonate_token")
		if (impersonateToken && impersonateToken !== "") {
			impersonateUserAuth(impersonateToken).then(() => {
				if (loading) {
					setLoading(false)
				}
			})
			return
		}

		const token = localStorage.getItem("token")
		if (token && token !== "") {
			loginToken(token)
		} else if (loading) {
			setLoading(false)
		}
	}, [loading, user, loginToken, impersonateUserAuth, state])

	// Effect: Relogin as User after establishing connection again
	useEffect(() => {
		if (state !== WebSocket.OPEN) {
			setAuthorised(false)
		} else if (!authorised && !reconnecting && !loading) {
			setReconnecting(true)
			const token = localStorage.getItem("token")
			if (token && token !== "") {
				;(async () => {
					await loginToken(token)
					onReconnect() // call queued outgoing messages
				})()
			}
		}
	}, [state, reconnecting, authorised, loginToken, onReconnect, loading])

	// Effect: Setup User Subscription after login
	useEffect(() => {
		if (!user || !subscribe) return
		return subscribe<User>(HubKey.UserUpdated, (u) => {
			if (u.id !== user.id) return

			setUser(u)
		})
	}, [user, subscribe])

	// Log out if an admin force disconnected you
	useEffect(() => {
		if (!user || !subscribe) return
		return subscribe<string>(HubKey.UserForceDisconnected, (id) => {
			if (id === user.id) logout()
		})
	}, [user, subscribe, logout])

	/////////////////
	//  Container  //
	/////////////////

	return {
		loginPassword,
		loginToken,
		loginGoogle,
		loginFacebook,
		logout,
		verify,
		hideVerifyComplete: () => setVerifyCompleteType(undefined),
		hasPermission,
		user: impersonatedUser || user,
		setUser,
		impersonateUser,
		impersonateUserAuth,
		isImpersonatingUser: impersonatedUser !== undefined,
		loading,
		verifying,
		verifyCompleteType,
	}
})

export const AuthProvider = AuthContainer.Provider
export const useAuth = AuthContainer.useContainer
